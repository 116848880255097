import Style from "./style"
import calRange from "./util/calRange"
import usePagenation from "./model/usePagenation"
import leftBlackSvg from "./asset/left_black.svg"
import leftGreySvg from "./asset/left_grey.svg"
import rightBlackSvg from "./asset/right_black.svg"
import rightGreySvg from "./asset/right_grey.svg"

const Pagenation = ({ nowPage, maxPage }) => {

    const range = calRange(nowPage, maxPage)
    const [prePageEvent, movePageEvent, nextPageEvent] = usePagenation(nowPage, maxPage)

    return (
        <Style.Wrapper>

            <Style.Button active={nowPage > 1} onClick={prePageEvent}>
                <img src={nowPage > 1 ? leftBlackSvg : leftGreySvg} /> 이전
            </Style.Button>

            <Style.PageButtonWrapper>
                {range?.map((elem) => {
                    return (
                        <Style.PageButton active={parseInt(nowPage) === elem} onClick={() => movePageEvent(elem)}>{elem}</Style.PageButton>
                    )
                })}
            </Style.PageButtonWrapper>

            <Style.Button active={nowPage < maxPage} onClick={nextPageEvent}>
                다음 <img src={nowPage < maxPage ? rightBlackSvg : rightGreySvg} />
            </Style.Button>

        </Style.Wrapper>
    )
}

export default Pagenation