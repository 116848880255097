import { useNavigate, useSearchParams } from "react-router-dom"

import Style from "./style"
import useGetContentRequest from "../../../../feature/content/useContentList"
import convertTimestamp from "shared/lib/convertTimestamp"
import Pagenation from "shared/ui/Pagenation"

const ArticleArea = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const pageIndex = searchParams.get("page")

    const [contentList] = useGetContentRequest(pageIndex)

    return (
        <Style.Wrapper>
            <Style.ArticleWrapper>
                {contentList?.data?.map(elem =>
                    <Style.Article onClick={() => navigate(`/content-detail/${elem?.contentId}`)}>
                        <Style.Thumbnail src={elem?.mainImageUrl} />
                        <Style.Text>{elem?.author}</Style.Text>
                        <Style.Title>{elem?.title}</Style.Title>
                        <Style.Content>{elem?.paragraph}</Style.Content>
                        <Style.Text>{convertTimestamp(elem?.createdAt, "date")}</Style.Text>
                    </Style.Article>
                )}
            </Style.ArticleWrapper>
            <Pagenation nowPage={pageIndex} maxPage={contentList?.totalPageCount} />
        </Style.Wrapper>
    )
}

export default ArticleArea