import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 120px 0 80px 0;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding: 10% 0;
            }
        }
    `,
    "MatchingBtn": styled.button`
        position: fixed;
        right: 56px;
        bottom: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.major};
        box-shadow: 2px 2px 10px #00000033;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                right: 20px;
                bottom: 20px;
                width: clamp(80px, 15vw, 120px);
                height: clamp(80px, 15vw, 120px);
            }
        }
    `,
    "MatchingImg": styled.img`
        width: 40%;
    `,
    "MatchingLabel": styled.p`
        color: ${({ theme }) => theme.color.white};
        ${({ theme }) => theme.font.small};
        margin-top: 8px;
    `,
    "MarginTopDiv": styled.div`
        margin-top: ${({ $size }) => `${$size}`};

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                margin-top: ${({ $mobile }) => `${$mobile}`};
            }
        }
`
}