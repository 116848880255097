import { useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useContentList = (pageIndex) => {
    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        setBackendState("GET", `/contents?page=${pageIndex - 1}`)
        window.scrollTo(0, 0)
    }, [pageIndex])

    return [backendState]
}

export default useContentList