import { Navigate, useNavigate, useSearchParams } from "react-router-dom"

import Style from "./style"
import SearchImg from "./asset/search.svg"

import Banner from "./ui/banner"
import CompanyList from "./ui/companyList"
import ArticleArea from "./ui/articleArea"

const Login = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const pageIndex = searchParams.get("page")

    if (!pageIndex) return <Navigate to="/?page=1" replace />

    return (
        <Style.WrapperDiv>
            <Banner />
            <Style.MarginTopDiv $size="40px" $mobile="16px">
                <CompanyList />
            </Style.MarginTopDiv>
            <Style.MarginTopDiv $size="80px" $mobile="36px">
                <ArticleArea />
            </Style.MarginTopDiv>
            <Style.MatchingBtn onClick={() => navigate("/matching")}>
                <Style.MatchingImg src={SearchImg} />
                <Style.MatchingLabel>상권찾기</Style.MatchingLabel>
            </Style.MatchingBtn>
        </Style.WrapperDiv>
    )
}

export default Login