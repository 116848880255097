

const applyContactFormat = (contactRef) => {

    const changeContactFormat = (e) => {
        const { value } = e.target
        contactRef.current.value = value
            .replace(/[^0-9]/g, "")
            .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/(-{1,2})$/g, "")
    }

    return [changeContactFormat]
}

export default applyContactFormat