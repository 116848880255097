import styled from "styled-components"

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 80px;
    `,
    "ArticleWrapper": styled.section`
        padding: 0 5%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(5, minmax(auto, 400px));
        grid-gap: 20px;

        @media only screen and (max-width: 2054px) {
            & {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        @media only screen and (max-width: 1527px) {
            & {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @media only screen and (max-width: 1185px) {
            & {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    `,
    "Article": styled.div`
        flex-grow: 1;
        flex-basis: 280px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        cursor: pointer;
    `,
    "Thumbnail": styled.img`
        width: 100%;
        height: 224px;
        object-fit: cover;
        border-radius: 16px;
        transition: all 100ms;
        &:hover {
            border-radius: 0px;
        }

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                border-radius: 8px;
                height: clamp(180px, 28vw, 224px);
            }
        }
    `,
    "Text": styled.p`
        ${({ theme }) => theme.font.extra_small};
        width: 100%;
    `,
    "Title": styled.h1`
        ${({ theme }) => theme.font.small_bold};
        width: 100%;
    `,
    "Content": styled.h2`
        ${({ theme }) => theme.font.extra_small};
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    `,
}