import { useSearchParams } from "react-router-dom"

const usePagenation = (nowPage, maxPage) => {
    const [, setSearchParams] = useSearchParams()

    const prePageEvent = () => {
        if (nowPage > 1) setSearchParams({ "page": parseInt(nowPage) - 1 })
    }

    const movePageEvent = (elem) => {
        setSearchParams({ "page": elem })
    }

    const nextPageEvent = () => {
        if (nowPage < maxPage) setSearchParams({ "page": parseInt(nowPage) + 1 })
    }

    return [prePageEvent, movePageEvent, nextPageEvent]
}

export default usePagenation